<template>
    <div id="pharmacy-list">

        <b-overlay :show="showLoading" spinner-variant="primary">
            <b-card>
                <ag-grid :agGrid="agGrid" pageTitle="Liste des fournisseurs" @refresh="loadData" :add="true"
                    @plusClicked="plusClicked" />
            </b-card>
        </b-overlay>

    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
// import ButtonCellRenderer from "@/views/components/ag-grid/ButtonCellRenderer.vue"
import BLinkCellRenderer from "./BLinkCellRenderer.vue";

export default {
    components: {
        AgGrid,
    },
    data() {
        return {
            showLoading: false,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Article",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.onButtonClick, },
                    },
                    {
                        headerName: "Code",
                        field: "id",
                    },
                    {
                        headerName: "Username",
                        field: "userAccount",
                    },
                    {
                        headerName: "Raison Sociale",
                        field: "entityName",
                        width: 300
                    },
                    {
                        headerName: "Tél",
                        field: "phone",
                    },
                    {
                        headerName: "E-mail",
                        field: "email",
                    },
                    {
                        headerName: "Type",
                        field: "type",
                    },
                    {
                        headerName: "Adresse",
                        field: "address",
                    },
                    {
                        headerName: "Code Postal",
                        field: "postalCode",
                    },
                ],
                rows: []
            },
        }
    },
    computed: {
    },
    created() {
        // this.$options.components.buttonCellRenderer = ButtonCellRenderer;
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.loadData();
    },
    methods: {
        async loadData() {
            this.showLoading = true;
            let response = await this.$http.get("_vendor").catch(() => {
                this.showLoading = false;
            });
            this.agGrid.rows = response.data;
            this.showLoading = false;
        },
        onButtonClick(data) {
            const routeData = this.$router.resolve({ name: 'supervisor-supp-items', params: { code: data.id } });
            window.open(routeData.href, '_blank');
        },
        plusClicked() {
            this.$router.push({ name: 'supervisor-vendor-new' });
        }
    }
}
</script>

<style lang="scss"></style>
