<template>
    <div>
        <div>
            <b-link href="#" class="font-weight-bold text-primary" style="border-bottom: 1px solid ;"
                @click="params.onButtonClick(params.data)">
                Articles
            </b-link>
        </div>
    </div>
</template>

<script>

export default {
};
</script>

<style scoped></style>